.aboutme{
    margin-top: 10rem;
}
.hi{
    font-size: 2rem;
    font-weight: light;
    color: #00bb00;
    padding: 0.5rem;
}
.name{
    color:#ccd6f6;
    font-size: 3rem;
    font-weight: bolder;
}
/* //typewriter css */
.css-typing p {
  border-right: .15em solid #00bb00;
  font-family: "Courier";
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}
.css-typing p:nth-child(1) {
  width: 9.3em;
  -webkit-animation: type 2s steps(40, end);
  animation: type 2s steps(40, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(2) {
  width: 11.5em;
  font-size: 3rem;
  opacity: 0;
  -webkit-animation: type2 2s steps(20, end);
  animation: type2 2s steps(40, end);
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing p:nth-child(3) {
  width: 14.3em;
  font-size: 3rem;
  opacity: 0;
  -webkit-animation: type3 5s steps(20, end), blink .5s step-end infinite alternate;
  animation: type3 5s steps(20, end), blink .5s step-end infinite alternate;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: .15em solid #00bb00;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: .15em solid #00bb00;
  }
  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: .15em solid #ccd6f6;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  99.9% {
    border-right: .15em solid #ccd6f6
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    border-color: tranparent;
  }
}
@media screen and (max-width: 960px) {
.css-typing p:nth-child(2) {
  font-size: 2rem;
}

.css-typing p:nth-child(3) {
  font-size: 2rem;
}
}